import React from "react";
import { mergeStyleSets } from "@fluentui/react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Frame from "../components/Frame";
import EmbedReport from "../components/powerbi/EmbedReport";

const EmbeddedExamplePage: React.FunctionComponent<Record<string, unknown>> = () => {
    const headerStyle = mergeStyleSets({
        embedRoot: {
            flex: `0 1 auto`,
        },
    });

    return (
        <Layout>
            <div className={headerStyle.embedRoot}>
                <SEO title="Power BI Embedded Analytics | Example" />
                <h1>Power BI Embedded Analytics | Example</h1>
            </div>
            <Frame>
                <EmbedReport reportId="a11829f1-0b66-48f3-b1b5-7fdcdf8f5969" />
            </Frame>
        </Layout>
    );
};

export default EmbeddedExamplePage;
